import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
import intersect from '@alpinejs/intersect'
import anchor from '@alpinejs/anchor'
import persist from '@alpinejs/persist'

Alpine.plugin(persist)
Alpine.plugin(anchor)
Alpine.plugin(intersect)
Alpine.plugin(focus)
Alpine.plugin(collapse)

window.Alpine = Alpine